<template>
  <b-card no-body class="card-statistics">
    <b-card-header style="margin-top:-5px;">
      <b-card-title>Statistics</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body" style="margin-top:-40px; margin-bottom:-20px ">
      <b-row>
        <b-col v-for="item in statisticsItems" :key="item.icon" xl="3" sm="6" :class="item.customClass">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: 'Rp. 1.000.000.000',
          subtitle: 'Pagu Anggaran',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: 'Rp. 500.000.000',
          subtitle: 'Realiasai Periode Lalu',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: 'Rp. 100.000.000',
          subtitle: 'Realisasi Periode Ini',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '50%',
          subtitle: 'Prosentase',
          customClass: '',
        },
      ],
    }
  },
  props: {
  },
}
</script>
