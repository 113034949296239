<template>
  <div>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50"></label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="3" sm="8" class="my-1">

      </b-col>
      <b-col md="4" class="my-1">
        <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Cari SKPD" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3" class="my-1">
        <b-button variant="info" block size="sm" v-b-modal.modal-1>
          <feather-icon size='15' icon="PlusCircleIcon"></feather-icon> Input Target Pendapatan SKPD
        </b-button>
      </b-col>

      <b-col cols="12">
        <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
          :filter-included-fields="filterOn" @filtered="onFiltered">
          <template #cell(wbs)="data">
            <b-row>
              <b-col md="4">
                <h6 class="text-primary"> <small class="text-primary">Nama SKPD</small><br>
                  <strong>{{ data.item.kode_dinas }} - {{ data.item.nama_dinas }}</strong>
                </h6>
              </b-col>
              <b-col md="4">
                <h3><small>Target Pendapatan </small><br><strong class="text-primary"><sup>Rp. </sup>{{
                  Rp(data.item.pagu_anggaran) }}</strong></h3>
              </b-col>
              <b-col md="0">
                <!-- <h6><small>Realisasi Periode Lalu</small><br><strong class="text-primary"><sup>Rp. </sup>{{
                  Rp(data.item.pagu_anggaran) }}</strong></h6>
                <h6><small>Periode Ini</small><br><strong class="text-primary"><sup>Rp. </sup>{{
                  Rp(data.item.pagu_anggaran) }}</strong>
                </h6> -->
              </b-col>
              <b-col md="2">
                <!-- <h6><small>Total Realisasi</small><br><strong class="text-primary"><sup>Rp. </sup>{{
                  Rp(data.item.pagu_anggaran) }}</strong></h6>
                <h6><small>Prosentase Realisasi</small><br><strong class="text-primary"> {{
                  Rp(data.item.prosentase) }} %</strong></h6> -->
                <b-button variant="outline-warning" size="sm" block @click="EditData(data.item)" v-b-modal.modal-2>
                  <feather-icon icon="EditIcon" size='15' /> Edit Target Pendapatan
                </b-button>
              </b-col>
              <b-col md="2">

                <b-button variant="outline-danger" size="sm" block @click="DeleteData(data.item)">
                  <feather-icon icon="TrashIcon" size='15' />Hapus Target Pndapatan
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-col>

      <b-col cols="6">
        Total Data {{ totalRows }}
      </b-col>
      <b-col cols="6">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
          class="my-0" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  props: {
    rs_data: {},
    totalRowss: {}
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [10, 25, 50, 100],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],

    }
  },
  computed: {
    items: {
      get: function () {
        return this.rs_data;
      },
      set: function (newValue) {
        return this.rs_data = newValue;
      }
    },
    totalRows: {
      get: function () {
        return this.items.length;
      },
      set: function (newValue) {
        return this.totalRows = newValue;
      }
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
  },
  emits: ['DeleteData', 'EditData'],
  methods: {
    DeleteData(val) {
      if (confirm('Apakah anda yaki akan menghapus data ini..?')) {
        this.$emit('DeleteData', val);
      }
    },
    EditData(val) {
      this.$emit('EditData', val)
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>